import "core-js/modules/es.array.push.js";
import {
// NavBar,
Swipe, SwipeItem, Image as VanImage, Button, Lazyload, Cell, Rate, Icon, ImagePreview, Divider } from 'vant';
import 'vant/es/image-preview/style';
import Vue from 'vue';
import SkuSelector from './SkuSelector.vue';
import { pickOutCusPath } from '../../../services/commonFunction.js';
Vue.use(Lazyload);
import { detail, productComments } from '../../../api/product';
import BackOff from '../../common/BackOff.vue';
export default {
  components: {
    BackOff,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Rate.name]: Rate,
    [Icon.name]: Icon,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Divider.name]: Divider,
    SkuSelector
  },
  data() {
    return {
      sellerLimitMaxCount: 0,
      sellerLimitMinCount: 0,
      // isActive: true,
      detailImageFlag: true,
      bannerImgList: [],
      baseInfo: {},
      labels: [],
      commentInfo: {},
      commentList: [],
      commentCount: 0,
      skuList: [],
      detailImgList: [],
      productId: this.$route.query.productId,
      skuSelectorShow: false
    };
  },
  created: async function () {
    this.getDetail();
    this.getProductComments();
  },
  methods: {
    displayDetailImage() {
      this.detailImageFlag = true;
    },
    displayReviews() {
      this.detailImageFlag = false;
    },
    getProductComments() {
      productComments(this.productId).then(res => {
        this.commentList = res.data.data.list;
        this.commentCount = res.data.data.totalCount;
        // this.commentAvgScoreText = this.commentInfo.totalCount + '(' + this.commentInfo.avgScore + ')'
      });
    },

    getDetail() {
      detail(this.productId).then(res => {
        this.bannerImgList = res.data.data.bannerImgs;
        this.baseInfo = res.data.data.baseInfo;
        this.labels = res.data.data.labels;
        // this.commentInfo = res.data.data.commentInfo
        // this.commentAvgScoreText = this.commentInfo.totalCount + '(' + this.commentInfo.avgScore + ')'
        this.detailImgList = res.data.data.detailImgs;
        this.skuList = res.data.data.skuList;
        this.sellerLimitMaxCount = Number(res.data.data.sellerLimit.maxCount);
        this.sellerLimitMinCount = Number(res.data.data.sellerLimit.minCount);
        console.log(this.sellerLimitMaxCount, this.sellerLimitMinCount);
      });
    },
    goToCus() {
      const productId = this.productId;
      // console.log('go =' + productId)
      // 单sku自动跳转
      if (this.skuList.length == 1) {
        const skuId = this.skuList[0].id;
        const path = pickOutCusPath(this.baseInfo.type);
        if (path) {
          this.$router.push({
            path: path,
            query: {
              productId: productId,
              skuId: skuId,
              sellerLimitMaxCount: this.sellerLimitMaxCount,
              sellerLimitMinCount: this.sellerLimitMinCount
            }
          });
        }
      } else {
        // 多sku弹出选择抽屉
        this.skuSelectorShow = true;
      }
    },
    goToCommentList() {
      this.$router.push({
        path: '/product/feedback',
        query: {
          productId: this.productId
        }
      });
    },
    // 用户评论图片预览
    previewImg(comment, index) {
      ImagePreview({
        className: 'hhhhhh',
        images: comment.image,
        startPosition: index,
        closeable: true
      });
    },
    onClickLeft() {
      this.$Toast('返回');
    }
  }
};