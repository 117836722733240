var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "product-detail-box",
    staticStyle: {
      "background-color": "#EFEFEF"
    }
  }, [_c('back-off', {
    attrs: {
      "url": "/home",
      "title": "Product Detail"
    }
  }), _c('div', [_c('van-swipe', {
    staticClass: "my-swipe",
    attrs: {
      "autoplay": 3000,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.bannerImgList, function (image, index) {
    return _c('van-swipe-item', {
      key: index
    }, [_c('img', {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: image,
        expression: "image"
      }],
      staticStyle: {
        "width": "100vw",
        "height": "100vw"
      },
      attrs: {
        "alt": "",
        "fit": "cover"
      }
    })]);
  }), 1)], 1), _vm.baseInfo.currencyCode !== undefined ? _c('div', {
    staticClass: "product-detail-base-info-box",
    staticStyle: {
      "width": "94.87vw",
      "background-color": "white",
      "margin-top": "2vw",
      "margin-bottom": "2vw",
      "margin-left": "2.565vw",
      "border-radius": "5px",
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "94.87vw",
      "height": "12.82vw",
      "margin-top": "0vw",
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "product-detail-sale-price-box",
    staticStyle: {
      "display": "inline-block",
      "margin-left": "4vw",
      "font-size": "20px"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.baseInfo.currencyCode) + " " + _vm._s((_vm.baseInfo.salePrice / 100).toFixed(2)) + " ")])]), _c('div', {
    staticStyle: {
      "margin-left": "4vw",
      "display": "inline-block",
      "font-size": "16px"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "6.41vw",
      "display": "flex",
      "align-items": "center",
      "text-decoration": "line-through",
      "color": "#b7b7b7"
    }
  }, [_vm._v(_vm._s((_vm.baseInfo.originPrice / 100).toFixed(2)))]), _c('div', {
    staticStyle: {
      "width": "21.54vw",
      "height": "6.41vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "background-color": "#D56460"
    }
  }, [_vm._v("   " + _vm._s(_vm.baseInfo.discount) + "   ")])])]), _c('div', {
    staticStyle: {
      "width": "94.87vw",
      "margin-top": "1.54vw",
      "margin-bottom": "1.54vw"
    }
  }, [_c('div', {
    staticClass: "product-detail-base-info-title-box",
    staticStyle: {
      "width": "90.87vw",
      "margin-left": "4vw",
      "display": "flex",
      "align-items": "center",
      "font-size": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.baseInfo.title) + " ")])])]) : _vm._e(), _vm.labels.length > 0 ? _c('div', {
    staticClass: "product-detail-labels-box",
    staticStyle: {
      "width": "94.87vw",
      "height": "12.82vw",
      "background-color": "white",
      "margin-top": "2vw",
      "margin-bottom": "2vw",
      "margin-left": "2.565vw",
      "border-radius": "5px",
      "overflow": "hidden",
      "display": "flex",
      "justify-content": "space-around"
    }
  }, _vm._l(_vm.labels, function (label, index) {
    return _c('div', {
      key: index,
      staticStyle: {
        "height": "8.97vw",
        "background-color": "#EEEEEE",
        "margin-top": "1.925vw",
        "border-radius": "5px",
        "overflow": "hidden",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "padding-left": "1.5vw",
        "padding-right": "1.5vw"
      }
    }, [_vm._v(" " + _vm._s(label) + " ")]);
  }), 0) : _vm._e(), _vm.commentList.length > 0 ? _c('div', {
    staticStyle: {
      "width": "100vw",
      "background-color": "white",
      "margin-top": "2vw",
      "font-size": "4.1vw"
    }
  }, [_c('van-button', {
    class: {
      'buttonClass': _vm.detailImageFlag
    },
    staticStyle: {
      "width": "49vw",
      "height": "8.97vw",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.displayDetailImage();
      }
    }
  }, [_vm._v("Product Detail")]), _c('van-button', {
    class: {
      'buttonClass': !_vm.detailImageFlag
    },
    staticStyle: {
      "width": "49vw",
      "height": "8.97vw",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.displayReviews();
      }
    }
  }, [_vm._v("Reviews(" + _vm._s(_vm.commentCount) + ")")])], 1) : _vm._e(), _vm.detailImageFlag ? _c('div', {
    staticStyle: {
      "background-color": "#f0f0ed"
    }
  }, [_vm._l(_vm.detailImgList, function (image, index) {
    return _c('img', {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: image,
        expression: "image"
      }],
      key: index,
      attrs: {
        "alt": "",
        "width": "100%",
        "height": "100%",
        "fit": "cover"
      }
    });
  }), _c('div', {
    staticStyle: {
      "background-color": "#f0f0ed"
    }
  }, [_vm._m(0), _c('div', {
    staticStyle: {
      "padding-right": "5vw",
      "padding-left": "5vw",
      "color": "aliceblue",
      "font-size": "3vw",
      "height": "13.59vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('a', {
    staticStyle: {
      "font-size": "3vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "text-decoration": "underline",
      "text-decoration-color": "blue"
    },
    attrs: {
      "href": "mailto:udefine@caiseshi.cn",
      "target": "_blank"
    }
  }, [_c('van-icon', {
    attrs: {
      "size": "25",
      "name": "envelop-o"
    }
  }), _vm._v(" If you have any questions, please click here to send us an email. ")], 1)]), _vm._m(1), _vm._m(2)])], 2) : _vm._e(), _vm.commentList.length > 0 && !_vm.detailImageFlag ? _c('div', {
    staticClass: "product-detail-comment-box"
  }, [_c('van-divider'), _c('div', {
    staticClass: "comment-list-box"
  }, _vm._l(_vm.commentList, function (comment, index) {
    return _c('div', {
      key: index,
      staticClass: "comment-item-box"
    }, [_c('div', {
      staticClass: "comment-item-user-info-box"
    }, [_c('div', {
      staticClass: "comment-item-user-avatar",
      staticStyle: {
        "width": "15.9vw",
        "height": "15.9vw",
        "align-self": "center",
        "margin-left": "5vw"
      }
    }, [_c('van-icon', {
      attrs: {
        "size": "15.9vw",
        "name": "user-circle-o"
      }
    })], 1), _c('div', {
      staticStyle: {
        "margin": "3vw",
        "align-self": "center",
        "float": "left",
        "font-size": "4.13vw"
      }
    }, [_c('div', [_vm._v(_vm._s(comment.user.name))]), _c('br'), _c('div', {
      staticStyle: {
        "height": "6.41vw",
        "position": "relative"
      }
    }, [_vm._v(_vm._s(comment.user.userId) + " ")])])]), _c('div', {
      staticClass: "comment-item-detail-box"
    }, [_c('div', {
      staticClass: "comment-item-context-box"
    }, [_vm._v(" " + _vm._s(comment.content) + " ")]), _c('div', {
      staticClass: "comment-item-pic-box"
    }, _vm._l(comment.image, function (image, index) {
      return _c('van-image', {
        key: index,
        staticClass: "comment-item-pic-item-box",
        attrs: {
          "width": "29%",
          "height": "29%",
          "lazy-load": "",
          "src": image
        },
        on: {
          "click": function ($event) {
            return _vm.previewImg(comment, index);
          }
        }
      });
    }), 1)]), _c('van-divider')], 1);
  }), 0)], 1) : _vm._e(), _c('SkuSelector', {
    attrs: {
      "productId": this.productId,
      "skuSelectorShow": _vm.skuSelectorShow,
      "baseInfo": _vm.baseInfo,
      "skuList": _vm.skuList,
      "sellerLimitMinCount": _vm.sellerLimitMinCount,
      "sellerLimitMaxCount": _vm.sellerLimitMaxCount
    },
    on: {
      "update:skuSelectorShow": function ($event) {
        _vm.skuSelectorShow = $event;
      },
      "update:sku-selector-show": function ($event) {
        _vm.skuSelectorShow = $event;
      }
    }
  }), _c('van-button', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "width": "100vw",
      "height": "12.52vw",
      "background-color": "#D56460",
      "border": "0px",
      "font-size": "20px",
      "color": "#EEEEEE"
    },
    on: {
      "click": function ($event) {
        return _vm.goToCus();
      }
    }
  }, [_vm._v(" Go To Customize ")])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "line-height": "0.9",
      "padding-right": "5vw",
      "padding-left": "5vw",
      "color": "aliceblue",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('p', [_c('span', {
    staticStyle: {
      "color": "green",
      "font-size": "4vw"
    }
  }, [_vm._v("Note:")]), _c('span', {
    staticStyle: {
      "color": "#515151",
      "font-size": "2vw",
      "margin-top": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v(" Manual measurements may have an error of 1-3mm. Please refer to the received physical item for accuracy.")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('h2', {
    staticStyle: {
      "justify-content": "center",
      "font-style": "unset",
      "color": "#383838"
    }
  }, [_vm._v("Shipping instructions")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "width": "90vw",
      "margin-left": "5vw",
      "font-size": "2.56vw",
      "color": "#515151"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "margin-bottom": "2vw"
    }
  }, [_c('div', {
    staticStyle: {
      "color": "green",
      "font-size": "10vw",
      "margin-right": "1vw"
    }
  }, [_vm._v("•")]), _c('p', {
    staticStyle: {
      "margin-top": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v(" Customized products will be shipped within 3-5 business days after placing the order. Thank you for your patience. ")])]), _c('div', {
    staticStyle: {
      "width": "90vw",
      "height": "0.2vw",
      "background-color": "#b7b7b7"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "margin-top": "2vw",
      "margin-bottom": "2vw"
    }
  }, [_c('div', {
    staticStyle: {
      "color": "green",
      "font-size": "10vw",
      "margin-right": "1vw"
    }
  }, [_vm._v("•")]), _c('p', {
    staticStyle: {
      "margin-top": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v(" At the moment, we do not support specifying a courier for logistics and shipping. We will try our best to arrange courier logistics based on the delivery address. ")])]), _c('div', {
    staticStyle: {
      "width": "90vw",
      "height": "0.2vw",
      "background-color": "#b7b7b7"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "margin-top": "2vw"
    }
  }, [_c('div', {
    staticStyle: {
      "color": "green",
      "font-size": "10vw",
      "margin-right": "1vw"
    }
  }, [_vm._v("•")]), _c('p', {
    staticStyle: {
      "margin-top": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v(" Customized products are tailored to your specific needs and are non-refundable or exchangeable unless there is a quality issue. We apologize for any inconvenience this may cause. ")])]), _c('br')]);
}];
export { render, staticRenderFns };