/**
 * @Date: 2023-07-08 13:55:21
 * @LastEditTime: 2023-07-08 19:45:38
 */
export function pickOutCusPath(productType) {
    var path = ''

    if (productType == 3) {
        path = '/editor/photobook'
    } else if (productType == 1) {
        path = '/editor/custom'
    } else if (productType == 2) {
        path = '/editor/even_photos'
    } else if (productType == 4) {
        path = '/editor/free_custom'
    }
    
    // console.log('path = ' + path)
    return path
}