import "core-js/modules/es.array.push.js";
import { ActionSheet } from 'vant';
import { pickOutCusPath } from '../../../services/commonFunction.js';
export default {
  components: {
    [ActionSheet.name]: ActionSheet
  },
  props: {
    productId: {
      type: Number | String,
      require: true
    },
    skuSelectorShow: {
      type: Boolean,
      required: true
    },
    baseInfo: {
      type: Object,
      required: true
    },
    skuList: {
      type: Array,
      required: true
    },
    sellerLimitMinCount: {
      type: Number,
      required: false
    },
    sellerLimitMaxCount: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      internalSkuSelectorShow: this.skuSelectorShow,
      selectedSku: {
        id: '',
        name: '',
        coverImg: '',
        originalPrice: '',
        salePrice: '',
        currencyCode: ''
      }
    };
  },
  watch: {
    // 向子组件传递 props 的值是异步的，因此在子组件的 mounted 钩子函数中检查 skuList.length 时可能还没有接收到正确的值。
    skuList: {
      immediate: true,
      handler(newSkuList) {
        if (!this.selectedSku.id && newSkuList.length > 0) {
          this.skuSelected(newSkuList[0].id);
        }
      }
    },
    // 修改显示状态
    skuSelectorShow(newVal) {
      this.internalSkuSelectorShow = newVal;
    },
    // 如果显示状态为false，调用关闭，传递给父组建
    internalSkuSelectorShow(newVal) {
      if (newVal === false) {
        this.closeSkuSelector();
      }
    }
  },
  methods: {
    // 绑定选中的sku数据，并把skuList的选中状态修改
    skuSelected(skuId) {
      const found = this.skuList.find(sku => sku.id === skuId);
      found.selected = true;
      found.currencyCode = this.baseInfo.currencyCode;
      this.skuList.forEach(sku => {
        if (sku !== found) {
          sku.selected = false;
        }
      });
      this.selectedSku = found;
    },
    goToCus() {
      const productId = this.productId;
      const skuId = this.selectedSku.id;
      const path = pickOutCusPath(this.baseInfo.type);
      if (path) {
        this.$router.push({
          path: path,
          query: {
            productId: productId,
            skuId: skuId,
            sellerLimitMaxCount: this.sellerLimitMaxCount,
            sellerLimitMinCount: this.sellerLimitMinCount
          }
        });
      }
    },
    closeSkuSelector() {
      this.internalSkuSelectorShow = false;
      this.$emit('update:skuSelectorShow', false);
    }
  }
};