var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sku-info",
    staticStyle: {
      "margin-bottom": "12.52vw"
    }
  }, [_c('van-action-sheet', {
    attrs: {
      "title": "SKU Select"
    },
    model: {
      value: _vm.internalSkuSelectorShow,
      callback: function ($$v) {
        _vm.internalSkuSelectorShow = $$v;
      },
      expression: "internalSkuSelectorShow"
    }
  }, [_c('div', {
    staticClass: "sku-info-selected"
  }, [_c('div', {
    staticClass: "sku-info-selected-img"
  }, [_c('img', {
    staticClass: "sku-info-selected-sku-image",
    attrs: {
      "src": _vm.selectedSku.coverImg,
      "alt": "img"
    }
  })]), _c('div', {
    staticClass: "sku-info-selected-n-p"
  }, [_c('p', {
    staticClass: "sku-info-selected-name"
  }, [_vm._v(_vm._s(_vm.selectedSku.name))]), _c('p', {
    staticClass: "sku-info-selected-o-price"
  }, [_vm._v(_vm._s(_vm.selectedSku.currencyCode) + _vm._s((_vm.selectedSku.originalPrice / 100).toFixed(2)))]), _c('p', {
    staticClass: "sku-info-selected-price"
  }, [_vm._v(_vm._s((_vm.selectedSku.salePrice / 100).toFixed(2)))])])]), _c('div', {
    staticClass: "sku-selector-container"
  }, _vm._l(_vm.skuList, function (sku) {
    return _c('div', {
      key: sku.id,
      staticClass: "sku-selector",
      class: {
        'sku-selector-selected': sku.id === _vm.selectedSku.id
      },
      on: {
        "click": function ($event) {
          return _vm.skuSelected(sku.id);
        }
      }
    }, [_c('span', {
      staticClass: "sku-selector-option"
    }, [_vm._v(_vm._s(sku.name))])]);
  }), 0), _c('div', {
    staticClass: "next-button-container"
  }, [_c('van-button', {
    staticClass: "next-button",
    on: {
      "click": function ($event) {
        return _vm.goToCus();
      }
    }
  }, [_vm._v("Next")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };